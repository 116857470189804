import { ref, computed, type UnwrapRef } from 'vue';

export interface IGenericForm<TFields> {
  fields: UnwrapRef<TFields>;
  errors: string[];
}

interface IUpdateErrorsFunction {
  (fieldHasError: boolean, field: string): void;
}

interface ICheckFormValidityFunction {
  (formId: string): Promise<boolean>;
}

export interface IFormCreatorResponse<TFields> {
  form: Ref<IGenericForm<UnwrapRef<TFields>>>;
  valid: ComputedRef<boolean>;
  updateErrors: IUpdateErrorsFunction;
  checkFormValidity: ICheckFormValidityFunction;
}

export function useFormCreator<TFields>(fields: UnwrapRef<TFields>): IFormCreatorResponse<TFields> {
  // refs
  const form = ref<IGenericForm<TFields>>({
    fields: fields,
    errors: []
  });

  // methods
  const updateErrors: IUpdateErrorsFunction = (fieldHasError: boolean, field: string): void => {
    if (fieldHasError) {
      form.value.errors.push(field);
    } else {
      form.value.errors = form.value.errors.filter(e => e !== field);
    }
  };

  const checkFormValidity = (formId: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const formV = !!(document.getElementById(formId) as HTMLFormElement).checkValidity() && valid.value;
        resolve(formV);
      }, 0);
    });
  };

  // computed
  const valid = computed(() => !form.value.errors.length);

  return {
    form,
    valid,
    updateErrors,
    checkFormValidity
  };
}
